<script lang="ts" setup>
import type { ComponentBlockFooter } from "~~/types/graphql";

const props = withDefaults(
  defineProps<{
    headline: ComponentBlockFooter["Titel"];
    link?: ComponentBlockFooter["link"];
  }>(),
  {},
);
</script>
<template>
  <footer class="footer bg-integer-dark-green">
    <div class="outer-space container pt-20 md:pt-25">
      <nuxt-link :to="link ?? ''">
        <h2
          class="hyphens-auto font-pixel text-h2 text-integer-light-green sm:max-w-4/5 md:max-2xl:text-h1 2xl:text-h1max"
        >
          {{ props.headline }}
        </h2>
      </nuxt-link>
    </div>
  </footer>
</template>
